<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="お知らせ" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <InfoDetail :info="info" class="l-box" />
        <div class="l-btns">
          <router-link
            to="/informations?isOnlyImportant=false&page=1&pageSize=10"
            class="c-btn secondary small"
            >お知らせ一覧へ</router-link
          >
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InfoDetail from "./components/InfoDetail";
import { API_URL } from "@/const";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "information-detail",
  components: {
    InfoDetail,
  },
  data() {
    return {
      id: null,
      info: {},
    };
  },
  methods: {
    async getInfo() {
      await axios
        .get(`${API_URL.INFO.SINGLE}/${this.id}`)
        .then((res) => {
          this.info = res.data;

          // 既読処理
          if (!this.$route.query.isRead) {
            axios.patch(`${API_URL.INFO.SINGLE}/${this.id}/read`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.prevRoute = from.fullPath
  //   })
  // },
  mounted() {
    this.id = this.$route.params.id;
    this.getInfo();
  },
};
</script>
