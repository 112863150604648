<template>
  <div class="c-infoDetail">
    <div class="c-infoDetail__head">
      <div class="c-infoDetail__head__info">
        <span v-if="info.isImportant" class="c-badge">重要</span>
        <span class="c-infoDetail__head__info__date">{{
          info.date | dateFormat
        }}</span>
      </div>
      <h2 class="c-lead-s">
        {{ info.title }}
      </h2>
    </div>
    <div class="c-infoDetail__body">
      <pre v-html="info.content"></pre>
    </div>
    <!-- データがないかもなので一旦コメントアウト -->
    <!-- <div class="c-infoDetail__foot">
      <a href="__undefined" class="c-text link-disc">https://www.zyyx.jp/</a>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
    },
  },
};
</script>
